import { title } from "process";
import Button from "../../ui/components/button/Button";
import { SoftwareDevelopmentType, TechTrainingType } from "../Types";
import { Assets } from "./Assets";

export const DATA = {
  socialLinks: {
    twitter: "https://x.com/Droidtechn?t=LVFJ6SEetP5DD1BHdSK0rQ&s=09",
    linkedin:
      "https://www.linkedin.com/company/d-roid-technologies-international/",
    instagram: "https://www.instagram.com/droidtechn?igsh=ZGUzMzM3NWJiOQ==",
    whatsapp: "https://wa.me/c/447886386437",
    email: "hr@droidtechinternational.com",
  },
  navLinks: [
    {
      link: "About Us",
      path: "/aboutus",
    },
    {
      link: "Software Development",
      path: "/software",
    },
    {
      link: "Tech Training",
      path: "/training",
    },
    {
      link: "Products",
      path: "/products",
    },
    {
      link: "Contact",
      path: "/contact",
    },
    {
      link: "More",
      path: "/more",
    },
  ],
  dropDownLinks: [
    {
      link: "Developer Tools",
      path: "/devtools",
    },
    {
      link: "D'roid Icons",
      path: "/droidicons",
    },
  ],

  courses: [
    { id: 1, title: "Secondary School Tech Trainings" },
    { id: 2, title: "University Tech Trainings" },
    { id: 3, title: "Organisations / Companies Tech Trainings" },
    { id: 4, title: "Basic Coding Training" },
    { id: 5, title: "Web Development Training" },
    { id: 6, title: "Mobile Development Training" },
    { id: 7, title: "Design Training" },
    { id: 8, title: "Project Management Training" },
  ],

  droneApproach: [
    {
      title: "Customized Solutions",
      content:
        "We understand that every project is unique, which is why we work closely with our clients to develop customized drone solutions that address their specific requirements and objectives. Whether it's aerial photography, mapping, or inspection, we have the expertise to deliver results that exceed expectations.",
      Button: "LEARN MORE",
    },
    {
      title: "State-of-the-Art Technology",
      content:
        "Our drones are equipped with the latest technology, including high-resolution cameras, LiDAR sensors, and thermal imaging capabilities. This allows us to capture detailed and accurate data from the air, providing valuable insights for our clients' projects.",
      Button: "LEARN MORE",
    },
    {
      title: "Safety & Compliance",
      content:
        "Safety is our top priority, and we adhere to strict safety protocols and regulations to ensure the safe operation of our drones. Our team of certified pilots undergoes rigorous training and certification processes to ensure compliance with aviation laws and regulations.",
      Button: "LEARN MORE",
    },
    {
      title: "Data Analysis & Reporting",
      content:
        "In addition to capturing aerial imagery and data, we also offer comprehensive data analysis and reporting services. Our team of data analysts and engineers processes and interprets the data collected by our drones, providing actionable insights and recommendations for our clients.",
      Button: "LEARN MORE",
    },
  ],
  FeaturedDroneServices: [
    {
      video: require("../../images/videos/dronevideo.mp4"),
      title: "AERIAL PHOTOGRAPHY AND VIDEOGRAPHY",
      content:
        "Capture stunning aerial images and videos for marketing, advertising, and promotional purposes.",
    },
  ],
  MappingSurveying: [
    {
      // image: require("../../images/png/drone survey.png"),
      // image: require("../../images/png/dronesurveying.png"),
      title: "MAPPING & SURVEYING",
      content:
        "Generate high-resolution maps and 3D models for land surveying, construction planning, and site analysis.",
    },
  ],
  InfrastructureInspection: [
    {
      image: require("../../images/png/infrastructure.png"),
      title: "INFRASTRUCTURE INSPECTION",
      content:
        "Conduct thorough inspections of infrastructure assets such as buildings, bridges, and power lines to identify defects and maintenance needs.",
    },
  ],
  CropMonitoringAgriculture: [
    {
      image: require("../../images/png/cropmonitorimage.png"),
      title: "CROP MONITORING & AGRICULTURE",
      content:
        "Monitor crop health, assess field conditions, and optimize agricultural operations using aerial imagery and data analysis.",
    },
  ],
  DroneModels: [
    {
      // image: require("../../images/png/dronemodel.png"),
      title: "Drone Models",
      content: " DJI Phantom, DJI Mavic, DJI Inspire, SenseFly eBee",
    },
  ],
  CamerasSensors: [
    {
      // image: require("../../images/png/camerasandsensors.png"),
      title: "Cameras & Sensors",
      content: " RGB cameras, multispectral cameras, LiDAR sensors",
    },
  ],
  SoftwareTools: [
    {
      // image: require("../../images/png/softwaretools.png"),
      title: "Software Tools",
      content: "Pix4D, DroneDeploy, Agisoft Metashape ",
    },
  ],
  equipmentApproach: [
    {
      title: "Consultation & Assessment",
      content:
        "We begin by conducting a thorough consultation with our clients to understand their equipment setup requirements and objectives. Our team assesses the available space, existing infrastructure, and technical specifications to develop a tailored setup plan.",
      Button: "LEARN MORE",
    },
    {
      title: "Hardware Installation",
      content:
        "Our technicians handle the installation of hardware components, including computers, servers, routers, switches, and other devices. We ensure that all equipment is installed correctly and configured to meet our clients' needs.",
      Button: "LEARN MORE",
    },
    {
      title: "Network Configuration",
      content:
        " We configure network settings, including IP addresses, DNS settings, and security protocols, to ensure seamless connectivity and data transfer within our clients' environments. Our goal is to create a stable and secure network infrastructure that supports their business operations.",
      Button: "LEARN MORE",
    },
    {
      title: "Testing & Optimization",
      content:
        "Once the setup is complete, we conduct thorough testing to ensure that all equipment and networks are functioning correctly. We optimize settings and configurations as needed to improve performance and reliability.",
      Button: "LEARN MORE",
    },
    {
      title: "Training & Documentation",
      content:
        "We provide training sessions for our clients' staff to familiarize them with the newly installed equipment and networks. Additionally, we create detailed documentation outlining setup procedures, troubleshooting steps, and best practices for future reference.",
      Button: "LEARN MORE",
    },
  ],

  testimonials: [
    {
      quote:
        "I have had the pleasure of working with D'roid Technologies on several projects, and I can confidently say that their expertise and dedication are unmatched. From the initial consultation to the final delivery, their team demonstrated a deep understanding of our needs and provided innovative solutions that exceeded our expectations. The software they developed for us is robust, user-friendly, and has significantly improved our operational efficiency. Their commitment to quality and customer satisfaction is evident in every interaction. I highly recommend D'roid Technologies to any organization looking for top-tier technology solutions.",
      author: "— Mark Ettan, Founder, LEADPAC Foundation",
    },
    {
      quote:
        "I have had the pleasure of working with D'roid Technologies on several projects, and I can confidently say that their expertise and dedication are unmatched. From the initial consultation to the final delivery, their team demonstrated a deep understanding of our needs and provided innovative solutions that exceeded our expectations. The software they developed for us is robust, user-friendly, and has significantly improved our operational efficiency. Their commitment to quality and customer satisfaction is evident in every interaction. I highly recommend D'roid Technologies to any organization looking for top-tier technology solutions.",
      author: "— Mark Ettan, Founder, LEADPAC Foundation",
    },
  ],

  droidStaff: [
    {
      image: require("../../images/png/uzo-onyekwelu.jpeg"),
      name: "Uzochukwu Felix",
      designation: "Frontend Developer",
      socials: {
        linkedin: "https://www.linkedin.com/in/uzochukwu-felix",
        twitter: "",
      },
    },
    {
      image: require("../../images/png/stella-eneh-two.jpg"),
      name: "Stella Eneh",
      designation: "Frontend Developer",
      socials: {
        linkedin: "https://www.linkedin.com/in/stella-eneh/",
        twitter: "https://twitter.com/StellaEneh_",
      },
    },

    {
      image: require("../../images/png/princewill-image-two.jpeg"),
      name: "Princewill Godwin",
      designation: "Product Designer",
      socials: {
        linkedin: "http://www.linkedin.com/in/princewill-godwin-uiuxdesigner",
        twitter: "https://x.com/princewill1_g ",
      },
    },
    {
      image: require("../../images/png/Amarachi-image-resize.jpg"),
      name: "Amarachi Abams",
      designation: "Product Designer",
      socials: {
        linkedin: "http://www.linkedin.com/in/amarachiabams",
        twitter: "https://x.com/Zibah_grafix?t=CH87kJA3wKHtvYLATLtApw&s=09 ",
      },
    },
    {
      image: require("../../images/png/chisom-iwuh-image.jpeg"),
      name: "Chisom Iwuh",
      designation: "Product Designer",
      socials: {
        linkedin: "https://www.linkedin.com/in/iwuh-chisom-884905218/",
        twitter: "",
      },
    },
    // {
    //   image: require("../../images/png/chidinmma.jpg"),
    //   name: "Chidinma Okoli",
    //   designation: "Digital Maketer",
    //   socials: {
    //     linkedin:
    //       "https://www.linkedin.com/in/chidinma-okoli-16b24832a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    //     twitter: "https://x.com/NightB80193?t=8niahHpoo5TpZDZcGm2bmQ&s=09 ",
    //   },
    // },
  ],
};

export const TechTraining: TechTrainingType[] = [
  {
    title: "One-On-One Training",
    description:
      "Unlock your full potential with personalized, one-on-one tech training at D'roid Technologies. Our expert trainers provide customized sessions tailored to your unique learning style and goals, ensuring you gain the skills and confidence to excel in today's fast-paced tech landscape. Whether you're looking to master programming languages, delve into web development, or explore the latest tech tools, our individualized approach guarantees focused attention and hands-on learning. Elevate your tech expertise with our dedicated, immersive training experience.",
    trainingType: [
      {
        category: "Web Development",
        desc: "",
      },
      {
        category: "Mobile Development",
        desc: "",
      },
      {
        category: "Animation Developement",
        desc: "",
      },
      {
        category: "BackEnd Developement",
        desc: "",
      },
      {
        category: "UI / UX Designer",
        desc: "",
      },
    ],
    image: Assets.images.one_one,
    content: "",
    trainingProcedure: {
      stepOne: "",
      stepTwo: "",
      stepThree: "",
    },
    priceNG: [
      {
        category: "Web Development",
        price: "",
      },
      {
        category: "Mobile Development",
        price: "",
      },
      {
        category: "Animation Developement",
        price: "",
      },
      {
        category: "BackEnd Developement",
        price: "",
      },
      {
        category: "UI / UX Designer",
        price: "",
      },
    ],
    priceUK: [
      {
        category: "Web Development",
        price: "",
      },
      {
        category: "Mobile Development",
        price: "",
      },
      {
        category: "Animation Developement",
        price: "",
      },
      {
        category: "BackEnd Developement",
        price: "",
      },
      {
        category: "UI / UX Designer",
        price: "",
      },
    ],
    applicationProcedure: {
      stepOne: "",
      stepTwo: "",
      stepThree: "",
    },
  },
  {
    title: "Students Tech Training",
    description:
      "Unlock your potential with our comprehensive Students Tech Training program. Designed for aspiring tech enthusiasts, our training courses provide the skills and knowledge needed to excel in today's digital world. From coding basics to advanced programming languages, web development, and cybersecurity, our expert-led sessions cover a wide range of topics. Gain hands-on experience through interactive projects and real-world applications, preparing you for a successful career in technology.",
    trainingType: [
      {
        category: "Web Development",
        desc: "",
      },
      {
        category: "Mobile Development",
        desc: "",
      },
      {
        category: "Animation Developement",
        desc: "",
      },
      {
        category: "BackEnd Developement",
        desc: "",
      },
      {
        category: "UI / UX Designer",
        desc: "",
      },
    ],
    image: Assets.images.highSchool,
    content: "",
    trainingProcedure: {
      stepOne: "",
      stepTwo: "",
      stepThree: "",
    },
    priceNG: [
      {
        category: "Web Development",
        price: "",
      },
      {
        category: "Mobile Development",
        price: "",
      },
      {
        category: "Animation Developement",
        price: "",
      },
      {
        category: "BackEnd Developement",
        price: "",
      },
      {
        category: "UI / UX Designer",
        price: "",
      },
    ],
    priceUK: [
      {
        category: "Web Development",
        price: "",
      },
      {
        category: "Mobile Development",
        price: "",
      },
      {
        category: "Animation Developement",
        price: "",
      },
      {
        category: "BackEnd Developement",
        price: "",
      },
      {
        category: "UI / UX Designer",
        price: "",
      },
    ],
    applicationProcedure: {
      stepOne: "",
      stepTwo: "",
      stepThree: "",
    },
  },
  {
    title: "University Tech Training",
    description:
      "Our University Tech Training program, designed to equip students with the essential skills and knowledge needed to excel in today's rapidly evolving tech landscape. Our comprehensive training courses cover a wide range of topics, including software development, cybersecurity, data analytics, artificial intelligence, and more.",
    trainingType: [
      {
        category: "Web Development",
        desc: "",
      },
      {
        category: "Mobile Development",
        desc: "",
      },
      {
        category: "Animation Developement",
        desc: "",
      },
      {
        category: "BackEnd Developement",
        desc: "",
      },
      {
        category: "UI / UX Designer",
        desc: "",
      },
    ],
    image: Assets.images.uni,
    content: "",
    trainingProcedure: {
      stepOne: "",
      stepTwo: "",
      stepThree: "",
    },
    priceNG: [
      {
        category: "Web Development",
        price: "",
      },
      {
        category: "Mobile Development",
        price: "",
      },
      {
        category: "Animation Developement",
        price: "",
      },
      {
        category: "BackEnd Developement",
        price: "",
      },
      {
        category: "UI / UX Designer",
        price: "",
      },
    ],
    priceUK: [
      {
        category: "Web Development",
        price: "",
      },
      {
        category: "Mobile Development",
        price: "",
      },
      {
        category: "Animation Developement",
        price: "",
      },
      {
        category: "BackEnd Developement",
        price: "",
      },
      {
        category: "UI / UX Designer",
        price: "",
      },
    ],
    applicationProcedure: {
      stepOne: "",
      stepTwo: "",
      stepThree: "",
    },
  },
];

export const softwareDevMain: SoftwareDevelopmentType[] = [
  {
    title: "Web Development",
    image: Assets.images.wDev,
    desc: "We specialize in delivering top-notch web development solutions tailored to meet the unique needs of your business. Our team of experienced developers and designers are dedicated to creating dynamic, responsive, and user-friendly websites that enhance your online presence and drive business growth.",
    price: [864.99, 785.99, 792.99],
    category: ["FinTech", "E-Commerce", "E-Learning,"],
    tools: ["Reactjs", "Nextjs", "HTML", "Css", "Javascript", "Typescript"],
    currency: ["$", "N", "e"],
    procedure: [
      {
        title: "Why Choose Our Web Development Service",
        subTitleOne: "Benefits of Working with Us:",
        subTitleOneContent: [
          {
            title: "Expertise and Experience:",
            desc: "Our team of seasoned developers has years of experience in creating custom, responsive, and user-friendly websites.",
          },
          {
            title: "Tailored Solutions:",
            desc: "We provide bespoke web development solutions that cater to your specific business needs.",
          },
          {
            title: "Cutting-edge Technologies:",
            desc: "We utilize the latest technologies and frameworks to ensure your website is fast, secure, and scalable.",
          },
          {
            title: "Comprehensive Support:",
            desc: "Enjoy ongoing support and maintenance to keep your website running smoothly.",
          },
          {
            title: "Proven Track Record:",
            desc: "With a portfolio of successful projects and satisfied clients, you can trust us to deliver excellence.",
          },
        ],
        subTitleTwo: "Values and Incentives:",
        subTitleTwoContent: [
          {
            title: "Client-Centric Approach:",
            desc: "We prioritize understanding your business goals to deliver a website that truly represents your brand.",
          },
          {
            title: "Transparent Communication:",
            desc: "Stay informed with regular updates and open communication throughout the development process.",
          },
          {
            title: "Innovative Solutions:",
            desc: "Our team continuously innovates to incorporate the best practices and emerging trends in web development.",
          },
          {
            title: "Competitive Pricing:",
            desc: "Get high-quality web development services at competitive prices, with clear and upfront cost estimates.",
          },
          {
            title: "Timely Delivery:",
            desc: "We are committed to delivering your project on time, every time, without compromising on quality.",
          },
        ],
      },
    ],
    path: "/software",
  },
  {
    title: "Mobile Development",
    image: Assets.images.mDev,
    desc: "We excel in crafting high-quality mobile applications that empower businesses to connect with their audiences in meaningful ways. Our expert team of developers and designers work collaboratively to deliver seamless, innovative, and user-friendly mobile solutions tailored to your specific needs.",
    price: [864.99, 785.99, 792.99],
    category: ["FinTech", "E-Commerce", "E-Learning,"],
    tools: ["React Native", "Flutter", "Javascript", "Typescript"],
    currency: ["$", "N", "e"],
    procedure: [
      {
        title: "Why Choose Our Web Development Service",
        subTitleOne: "Benefits of Working with Us:",
        subTitleOneContent: [
          {
            title: "Expertise and Experience:",
            desc: "Our team of seasoned developers has years of experience in creating custom, responsive, and user-friendly websites.",
          },
          {
            title: "Tailored Solutions:",
            desc: "We provide bespoke web development solutions that cater to your specific business needs.",
          },
          {
            title: "Cutting-edge Technologies:",
            desc: "We utilize the latest technologies and frameworks to ensure your website is fast, secure, and scalable.",
          },
          {
            title: "Comprehensive Support:",
            desc: "Enjoy ongoing support and maintenance to keep your website running smoothly.",
          },
          {
            title: "Proven Track Record:",
            desc: "With a portfolio of successful projects and satisfied clients, you can trust us to deliver excellence.",
          },
        ],
        subTitleTwo: "Values and Incentives:",
        subTitleTwoContent: [
          {
            title: "Client-Centric Approach:",
            desc: "We prioritize understanding your business goals to deliver a website that truly represents your brand.",
          },
          {
            title: "Transparent Communication:",
            desc: "Stay informed with regular updates and open communication throughout the development process.",
          },
          {
            title: "Innovative Solutions:",
            desc: "Our team continuously innovates to incorporate the best practices and emerging trends in web development.",
          },
          {
            title: "Competitive Pricing:",
            desc: "Get high-quality web development services at competitive prices, with clear and upfront cost estimates.",
          },
          {
            title: "Timely Delivery:",
            desc: "We are committed to delivering your project on time, every time, without compromising on quality.",
          },
        ],
      },
    ],
    path: "/software",
  },
  {
    title: "Back End Development",
    image: Assets.images.backEndDev,
    desc: "We specialize in providing robust and scalable back-end development solutions that power your applications and ensure seamless performance. Our experienced team of developers is dedicated to building secure, efficient, and maintainable server-side architectures that support the front-end user experience and drive business success.",
    price: [864.99, 785.99, 792.99],
    category: ["FinTech", "E-Commerce", "E-Learning,"],
    tools: ["React Native", "Flutter", "Javascript", "Typescript"],
    currency: ["$", "N", "e"],
    procedure: [
      {
        title: "Why Choose Our Web Development Service",
        subTitleOne: "Benefits of Working with Us:",
        subTitleOneContent: [
          {
            title: "Expertise and Experience:",
            desc: "Our team of seasoned developers has years of experience in creating custom, responsive, and user-friendly websites.",
          },
          {
            title: "Tailored Solutions:",
            desc: "We provide bespoke web development solutions that cater to your specific business needs.",
          },
          {
            title: "Cutting-edge Technologies:",
            desc: "We utilize the latest technologies and frameworks to ensure your website is fast, secure, and scalable.",
          },
          {
            title: "Comprehensive Support:",
            desc: "Enjoy ongoing support and maintenance to keep your website running smoothly.",
          },
          {
            title: "Proven Track Record:",
            desc: "With a portfolio of successful projects and satisfied clients, you can trust us to deliver excellence.",
          },
        ],
        subTitleTwo: "Values and Incentives:",
        subTitleTwoContent: [
          {
            title: "Client-Centric Approach:",
            desc: "We prioritize understanding your business goals to deliver a website that truly represents your brand.",
          },
          {
            title: "Transparent Communication:",
            desc: "Stay informed with regular updates and open communication throughout the development process.",
          },
          {
            title: "Innovative Solutions:",
            desc: "Our team continuously innovates to incorporate the best practices and emerging trends in web development.",
          },
          {
            title: "Competitive Pricing:",
            desc: "Get high-quality web development services at competitive prices, with clear and upfront cost estimates.",
          },
          {
            title: "Timely Delivery:",
            desc: "We are committed to delivering your project on time, every time, without compromising on quality.",
          },
        ],
      },
    ],
    path: "/software",
  },
  {
    title: "Code Reviews and Testing",
    image: Assets.images.cReviews,
    desc: "We offer comprehensive code review services designed to enhance the quality, security, and performance of your software. Our experienced team of developers meticulously examines your codebase to identify potential issues, optimize performance, and ensure best practices are followed.",
    price: [864.99, 785.99, 792.99],
    category: ["FinTech", "E-Commerce", "E-Learning,"],
    tools: ["React Native", "Flutter", "Javascript", "Typescript"],
    currency: ["$", "N", "e"],
    procedure: [
      {
        title: "Why Choose Our Web Development Service",
        subTitleOne: "Benefits of Working with Us:",
        subTitleOneContent: [
          {
            title: "Expertise and Experience:",
            desc: "Our team of seasoned developers has years of experience in creating custom, responsive, and user-friendly websites.",
          },
          {
            title: "Tailored Solutions:",
            desc: "We provide bespoke web development solutions that cater to your specific business needs.",
          },
          {
            title: "Cutting-edge Technologies:",
            desc: "We utilize the latest technologies and frameworks to ensure your website is fast, secure, and scalable.",
          },
          {
            title: "Comprehensive Support:",
            desc: "Enjoy ongoing support and maintenance to keep your website running smoothly.",
          },
          {
            title: "Proven Track Record:",
            desc: "With a portfolio of successful projects and satisfied clients, you can trust us to deliver excellence.",
          },
        ],
        subTitleTwo: "Values and Incentives:",
        subTitleTwoContent: [
          {
            title: "Client-Centric Approach:",
            desc: "We prioritize understanding your business goals to deliver a website that truly represents your brand.",
          },
          {
            title: "Transparent Communication:",
            desc: "Stay informed with regular updates and open communication throughout the development process.",
          },
          {
            title: "Innovative Solutions:",
            desc: "Our team continuously innovates to incorporate the best practices and emerging trends in web development.",
          },
          {
            title: "Competitive Pricing:",
            desc: "Get high-quality web development services at competitive prices, with clear and upfront cost estimates.",
          },
          {
            title: "Timely Delivery:",
            desc: "We are committed to delivering your project on time, every time, without compromising on quality.",
          },
        ],
      },
    ],
    path: "/software",
  },
  // {
  //   title: "Template Service",
  //   image: Assets.images.tService,
  //   desc: "We provide cutting-edge automated template generation services designed to streamline your content creation processes and boost productivity. Our solutions empower businesses to quickly generate consistent, high-quality templates for various applications, from marketing materials to business documents, saving valuable time and resources.",
  //   price: [864.99, 785.99, 792.99],
  //   category: ["FinTech", "E-Commerce", "E-Learning,"],
  //   tools: ["React Native", "Flutter", "Javascript", "Typescript"],
  //   currency: ["$", "N", "e"],
  //   procedure: [
  //     {
  //       title: "Why Choose Our Web Development Service",
  //       subTitleOne: "Benefits of Working with Us:",
  //       subTitleOneContent: [
  //         {
  //           title: "Expertise and Experience:",
  //           desc: "Our team of seasoned developers has years of experience in creating custom, responsive, and user-friendly websites.",
  //         },
  //         {
  //           title: "Tailored Solutions:",
  //           desc: "We provide bespoke web development solutions that cater to your specific business needs.",
  //         },
  //         {
  //           title: "Cutting-edge Technologies:",
  //           desc: "We utilize the latest technologies and frameworks to ensure your website is fast, secure, and scalable.",
  //         },
  //         {
  //           title: "Comprehensive Support:",
  //           desc: "Enjoy ongoing support and maintenance to keep your website running smoothly.",
  //         },
  //         {
  //           title: "Proven Track Record:",
  //           desc: "With a portfolio of successful projects and satisfied clients, you can trust us to deliver excellence.",
  //         },
  //       ],
  //       subTitleTwo: "Values and Incentives:",
  //       subTitleTwoContent: [
  //         {
  //           title: "Client-Centric Approach:",
  //           desc: "We prioritize understanding your business goals to deliver a website that truly represents your brand.",
  //         },
  //         {
  //           title: "Transparent Communication:",
  //           desc: "Stay informed with regular updates and open communication throughout the development process.",
  //         },
  //         {
  //           title: "Innovative Solutions:",
  //           desc: "Our team continuously innovates to incorporate the best practices and emerging trends in web development.",
  //         },
  //         {
  //           title: "Competitive Pricing:",
  //           desc: "Get high-quality web development services at competitive prices, with clear and upfront cost estimates.",
  //         },
  //         {
  //           title: "Timely Delivery:",
  //           desc: "We are committed to delivering your project on time, every time, without compromising on quality.",
  //         },
  //       ],
  //     },
  //   ],
  //   path: "/software",
  // },
];
// 2.

//
//
//
//
//
//
//
//
//
//

// Part 2: Preparing to Apply
// 3. How to Apply via Email:

// Step-by-Step Instructions:
// Gather Requirements:
// Outline your project requirements, including your business objectives, target audience, and desired features.
// Prepare Documentation:
// Compile any relevant documents, such as project briefs, wireframes, or design mockups.
// Write an Email:
// Send an email to our dedicated team at [contact email address]. Make sure to include:
// Your name and contact information.
// A brief introduction about your business.
// Detailed project requirements and expectations.
// Any questions or concerns you might have.
// Attachments:
// Attach any relevant documents or files that will help us understand your project better.
// 4. Important Steps Before Applying:

// Review Our Portfolio:
// Take a look at our previous projects to ensure our style and capabilities match your vision.
// Define Your Goals:
// Clearly define what you want to achieve with your new website, including specific functionalities and design preferences.
// Budget Considerations:
// Have a clear understanding of your budget and discuss it openly with us to ensure we can meet your expectations within the given constraints.
// Timeline Expectations:
// Determine your desired timeline for the project and communicate this to us to align our schedules.
// Research Competitors:
// Look at competitors’ websites to gather ideas and insights into what you like and don’t like.
// Initial Consultation:
// Schedule an initial consultation call with us to discuss your project in detail and get to know our team better.
// Part 3: Final Steps Before Applying
// 5. Before Clicking "Apply Now":

// Double-Check Requirements:
// Ensure you have all the necessary information and documents ready before sending your application email.
// Read Our FAQs:
// Review our Frequently Asked Questions (FAQ) section to address any common queries you might have.
// Prepare for Consultation:
// Be ready for a follow-up consultation call or meeting where we can further discuss your project and clarify any details.
// Subscribe to Our Newsletter:
// Stay updated with our latest services, offers, and industry insights by subscribing to our newsletter.
// Follow Us on Social Media:
// Connect with us on social media to see our latest work, client testimonials, and updates.
// Apply Now Button:
// Once you have completed all the necessary steps and feel prepared, click the "Apply Now" button to submit your application and start your journey with us.
